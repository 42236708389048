import MyAlbumState from '@/redux/slices/myAlbum/types';

export interface Video {
  _id: string;
  title: string;
  type: string;
  htmlURL: string;
  previewURL: string;
  thumbnailURL: string;
  disabled: boolean;
  usersLike?: number;
  videoURL: string;
  rawVideoURL: string;
  habitatId?: {
    _id: string;
    title: string;
    animal: string;
    zoo: {
      _id: string;
      name: string;
      slug: string;
    };
    slug: string;
  };
  creationDate: string;
  userId: string;
  rawURL: string;
  tags: Tag[];
  id: string;
  username: string;
  profile: {
    color: string;
    animalIcon: string;
  };
  zooSlug: string;
  zoo: string;
  isLiked: boolean;
  views: number;
}

export interface Tag {
  tag: string;
  users: string[];
}

export interface GetMediaResponse {
  media: MyAlbumState['album']['list'][number];
}

export interface AdminUploadMediaRequest {
  habitatId?: string;
  url: string;
  title: string;
  hidden?: boolean;
  type?: 'curated' | 'pastTalks' | 'clip';
  sendToChat?: {
    pinUntil?: 'pinned' | Date | false;
  };
  sendNotification?: {
    subject?: string;
    content?: string;
  };
}

export interface AdminUploadMediaResponse {
  _id: string;
}

export type VideoType = 'clip' | 'curated' | 'viv' | 'live' | 'livetalk' | 'trailer';

export const toVideoType = (type: string | null | undefined): VideoType | undefined => {
  const lowerCased = type?.toLowerCase();
  switch (lowerCased) {
    case 'clip':
    case 'curated':
    case 'viv':
    case 'live':
    case 'livetalk':
    case 'trailer':
      return lowerCased;
    default:
      return undefined;
  }
};
