import { PrimaryButton } from 'Components/Buttons';
import ImageSelector from 'Components/ImageSelector';
import Header from 'Components/modals/Header';
import { Box, CheckBox, Form, Heading, Layer, Text, TextInput } from 'grommet';
import { isEmpty } from 'lodash-es';
import { memo } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import { setShareModalData } from '@/components/ShareModal/shareModalSlice/actions';
import { useAdminUploadMediaMutation } from '@/hooks/media';
import { getMedia } from '@/queries/media';
import { useSelector } from '@/redux/helper';
import { RequestError } from '@/shared/fetch';

import style from './style.scss';

const EXPERT_TALK_FORMATS = ['video/*'];
const MEDIA_FORMATS = ['video/*', 'image/*'];

const MEDIA_TRANSFORM = Object.freeze({
  maxWidth: 1280,
  maxHeight: 720,
  forceAspectRatio: [16, 9],
});

const RECOMMENDED_SETTINGS = {
  ...MEDIA_TRANSFORM,
  fileSize: 100_000_000,
  duration: 600,
};

/**
 * Generates a UploadMediaButton component.
 *
 * @param {object} props - The properties for the component.
 * @param {string} [props.type] - The type of media to upload.
 * @param {string} [props.className] - The type of media to upload.
 * @param {import('preact/compat').ReactNode} [props.label] - The label for the button.
 * @returns {import('preact').JSX.Element} The rendered UploadMediaButton component.
 */
const UploadMediaButton = ({ className, type, label = 'Upload' }) => {
  const habitatId = useSelector((state) => state.habitat.habitatInfo?._id);
  const isExpertTalk = type === 'pastTalks';
  const isCurated = type === 'curated';
  const [showPopup, setShowPopup] = useState(false);
  const [shouldSendNotification, setShouldSendNotification] = useState(false);
  const [title, setTitle] = useState('');
  const [media, setMedia] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const mediaRef = useRef();
  const mediaPreviewRef = useRef();
  const {
    mutate,
    isPending: loading,
    isSuccess,
    reset,
  } = useAdminUploadMediaMutation({
    onSuccess: async (data) => {
      const media = await getMedia(data._id);
      dispatch(setShareModalData({ data: media, mediaId: data._id }));
      setShowPopup(false);
    },
    onError: (error) => {
      let str;
      if (error instanceof RequestError) {
        str = error.body?.error;
      }
      setError(str || 'Something went wrong');
    },
  });

  useEffect(() => {
    setTitle('');
    setError('');
    setMedia('');
    setShouldSendNotification(false);
    reset();
  }, [showPopup]);

  useEffect(() => {
    // when media is changed, clear errors
    setError('');
    reset();
  }, [media]);

  const onCloseHandler = () => {
    setShowPopup(false);
  };

  const onSubmit = async (e) => {
    setError('');
    const isFileValid = await mediaRef.current.validate();
    if (!isFileValid) {
      setError('Invalid file');
      return;
    }

    mutate({
      title,
      url: media,
      type,
      habitatId,
      ...(shouldSendNotification && isCurated
        ? {
            sendNotification: {
              subject: e.target.subject.value,
              content: e.target.content.value,
            },
          }
        : undefined),
    });
  };

  return (
    <>
      <PrimaryButton
        data="upload"
        size="small"
        label={label}
        onClick={() => setShowPopup(true)}
        margin={{ left: 'auto' }}
        className={className}
      />
      {showPopup && (
        <Layer onClickOutside={loading ? undefined : onCloseHandler}>
          <div className="flex max-h-[90svh] w-[90vw] max-w-[420px] flex-col">
            <Header onClose={loading ? undefined : onCloseHandler}>Upload Media</Header>

            <div className={style.contentContainer}>
              <div className={style.contentWrapper}>
                <div className={style.wrapper}>
                  <Form onSubmit={onSubmit} className={style.form}>
                    <Box margin={{ bottom: '20px' }}>
                      <Heading margin={{ top: '0', bottom: '5px' }} level="5">
                        Media:
                      </Heading>
                      {!isEmpty(media) && (
                        <Box margin={{ bottom: '20px' }}>
                          {media.includes('.mp4') && (
                            <video
                              ref={mediaPreviewRef}
                              src={media}
                              autoPlay
                              onPlay={() => mediaRef.current?.validate()}
                              muted
                              playsInline
                              controls
                            />
                          )}

                          {!media.includes('.mp4') && (
                            <img
                              ref={mediaPreviewRef}
                              src={media}
                              alt=""
                              onLoadedData={() => mediaRef.current?.validate()}
                              className="aspect-video bg-black object-contain"
                            />
                          )}
                        </Box>
                      )}

                      <ImageSelector
                        url={media}
                        ref={mediaRef}
                        previewRef={mediaPreviewRef}
                        placeholder="https://"
                        constraints={{
                          acceptedFormats: isExpertTalk ? EXPERT_TALK_FORMATS : MEDIA_FORMATS,
                        }}
                        recommendedSettings={RECOMMENDED_SETTINGS}
                        transform={MEDIA_TRANSFORM}
                        onChange={(value) => setMedia(value)}
                      />
                    </Box>

                    <Box margin={{ bottom: '20px' }}>
                      <Heading margin="0" level="5">
                        Title:
                      </Heading>
                      <TextInput
                        placeholder="write a short description"
                        value={title}
                        maxLength={100}
                        onChange={({ target: { value } }) => setTitle(value)}
                        className={style.input}
                      />
                    </Box>

                    {isCurated && (
                      <Box margin={{ bottom: '20px' }}>
                        <CheckBox
                          checked={shouldSendNotification}
                          name="pin"
                          label="Send notification"
                          onChange={(event) => setShouldSendNotification(event.currentTarget.checked)}
                        />
                        {shouldSendNotification && (
                          <div className="ml-[26px] mt-2">
                            <Heading margin="0" level="5">
                              Subject:
                            </Heading>
                            <input
                              placeholder="Subject of the notification"
                              name="subject"
                              className="preflight preflight-input w-full border-b pb-1 pt-2 text-xl font-bold placeholder:text-xs placeholder:text-grey-4"
                            />
                            <Heading margin="0" level="5">
                              Content:
                            </Heading>
                            <textarea
                              placeholder="Content of the notification"
                              name="content"
                              rows={2}
                              maxLength={100}
                              className="preflight preflight-input w-full border-b pb-1 pt-2 text-xl font-bold placeholder:text-xs placeholder:text-grey-4"
                            />
                          </div>
                        )}
                      </Box>
                    )}

                    <Box margin={{ top: 'auto' }}>
                      {error && (
                        <Box alignSelf="center">
                          <Text color="red">{error}</Text>
                        </Box>
                      )}

                      {isSuccess && (
                        <Box alignSelf="center">
                          <Text color="green">Success</Text>
                        </Box>
                      )}

                      <Box alignContent="center" margin={{ top: '20px' }}>
                        <PrimaryButton
                          className={style.button}
                          loading={loading}
                          type="submit"
                          disabled={!title || isSuccess || !media}
                          size="large"
                          label="Submit"
                        />
                      </Box>
                    </Box>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Layer>
      )}
    </>
  );
};

export default memo(UploadMediaButton);
